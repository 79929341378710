@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
a {
  color: inherit;
}

body {
  background: rgb(248, 248, 246);
  font-family: "Open Sans", sans-serif;
}

.app {
  height: 115vh;
  display: flex;
  flex-direction: column;
}

.go-back-text {
  padding: 24px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
}

.go-back-text:hover {
  text-decoration: underline;
}

.main-app {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 780px;
  margin: 0 auto;
  margin-bottom: 80px;
  border-radius: 8px;
  overflow: hidden;
}

.header {
  background: #222;
  color: #fff;
  font-weight: 500;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
  justify-content: center;
}
.header {
  padding: 16px;
}

.x-wrapper {
  position: absolute;
  top: 9px;
  right: 9px;
  border: solid 2px #fff;
  border-radius: 50%;
  display: flex;
  cursor: pointer;
}

.header img {
  height: 24px;
  width: auto;
}

.GivexButton1 {
  width: 260px;
  height: 70px;
  background: #6fdd54;
}

.GivexButton3 {
  width: 260px;
  height: 70px;
  background: #cd89e2;
}

.GivexButton4 {
  width: 260px;
  height: 70px;
  background: #f3f336;
}

.main-content {
  background: #373737;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.search-wrapper {
  margin-bottom: 24px;
  display: flex;
  padding: 8px;
  background: #222;
  border-radius: 8px;
  align-items: center;
}

.search-wrapper img {
  height: 24px;
  width: auto;
}

.search-wrapper input {
  background: none;
  outline: none;
  border: none;
  flex: 1 1;
  color: #eee;
  margin-left: 8px;
  font-size: 1.1rem;
}



.copy-wrapper {
  margin-bottom: 24px;
  display: flex;
  padding: 8px;
  background: #222;
  border-radius: 10px;
  align-items: center;
}

.copy-wrapper img {
  height: 54px;
  width: auto;
}

.copy-wrapper input {
  background: none;
  outline: none;
  border: none;
  flex: 1 1;
  color: #eee;
  margin-left: 10px;
  font-size: 1.1rem;
}

.clear-img {
  cursor: pointer;
}
.loader-img {
  margin-left: 6px;
}

.search-button {
  color: #fff;
  background: rgb(59, 182, 27);
  background: linear-gradient(
    0deg,
    rgba(59, 182, 27, 1) 0%,
    rgba(94, 223, 57, 1) 62%
  );
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 8px;
}


.copy-button {
  color: #fff;
  background: rgb(59, 182, 27);
  background: linear-gradient(
    0deg,
    rgb(19, 15, 228) 0%,
    rgb(20, 16, 236) 62%
  );
  border: none;
  outline: none;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 8px;
}


.table-wrapper {
  position: relative;
  background: #222;
  border-radius: 8px;
  flex: 1 1;
  overflow-y: auto;
}

.table {
  position: absolute;
  width: 100%;
  border-collapse: collapse;
}

tr {
  border-bottom: 1px solid rgb(83, 83, 83);
  padding: 16px;
  cursor: pointer;
}

.table td {
  padding: 16px;
}
.table-wrapper p {
  color: #fff;
  font-weight: 500;
}
.last-td {
  text-align: right;
}

.page {
  flex: 1 1;
  background: #222;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.3rem;
}

.page p {
  margin-top: 40px;
}

.page img {
  height: 240px;
  width: auto;
}
.page .grey-text {
  color: rgb(168, 168, 168);
  margin-top: 10px;
}

.footer {
  border-radius: 8px;
  margin-top: 16px;
}
.custom-button {
  background: #222;
  color: #fff;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: pointer;
}

.lottie-anim {
  height: 150px;
  width: 100%;
}

